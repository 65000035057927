<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <h1 class="big-title">Create a Facebook or Instagram ad</h1>
    </header>

    <form ref="mainForm" action="#" class="create-fb-ads-form" @submit="saveAdz">
      <div class="input-label-wrap" :class="{ filled: adz.name }">
        <input v-model="adz.name" type="text" class="label-focus" required />
        <p class="label">Ad name</p>
      </div>
      <div class="checkbox-wrap">
        <div class="checkbox" :class="{ active: adz.type === 'single' }" @click="selectType('single')">
          <div class="wrap">
            <img alt="Single" src="@/assets/img/fb-simple-produit.svg" />
            <p>Image/Video</p>
          </div>
        </div>
        <div class="checkbox" :class="{ active: adz.type === 'multiple' }" @click="selectType('multiple')">
          <div class="wrap">
            <img alt="Multiple" src="@/assets/img/fb-carrousel.svg" />
            <p>Carrousel</p>
          </div>
        </div>
        <div class="checkbox" :class="{ active: adz.type === 'stories' }" @click="selectType('stories')">
          <div class="wrap">
            <img alt="Sotries" src="@/assets/img/story-icon.svg" />
            <p>Story</p>
          </div>
        </div>
      </div>
      <div class="bt-wrap">
        <button type="submit" class="bt save" @mouseover="setAction('exit')">
          <p>Save and exit</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
        </button>
        <button type="submit" to="/CreateFacebookAds" class="bt" @mouseover="setAction('next')">
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
          <p>Next</p>
        </button>
      </div>
    </form>

    <div class="small-outline-bt dark" @click="browserBack">
      <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
      <p>Back</p>
    </div>
  </section>
</template>

<script>
import { isNil } from 'lodash'
import { mapState, mapActions } from 'vuex'
import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'
import { doAlert } from '@/misc/helpers'

export default {
  head: function () {
    return {
      title: {
        inner: 'Create a Facebook ad',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} Select your FB ad format`,
          id: 'desc',
        },
      ],
    }
  },

  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          this.selectCampaign({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          })
        }
      },
      immediate: true,
    },
    selectedCampaign: {
      async handler(selectedCampaign) {
        if (!isNil(selectedCampaign)) {
          if (selectedCampaign.nbAdz >= this.accountMembership.config.adz) {
            this.$router.push({ name: 'Campaign', query: { membershipGuard: true } })
            return
          }
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      adz: {
        name: '',
        type: null,
        format: '',
        isReady: false,
        status: 'draft',
        isApproved: false,
        isArchived: false,
        content: {
          media: '',
          text: '',
          url: '',
          urlText: '',
          metaTitle: '',
          metaDesc: '',
          cta: '',
        },
      },
      mode: 'create',
      action: 'exit',
    }
  },

  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('users', ['account', 'accountMembership', 'selectedCampaign']),
  },

  methods: {
    browserBack() {
      return this.$router.go(-1)
    },

    setAction(action) {
      this.action = action
    },

    selectType(type) {
      this.adz.type = type
    },

    async saveAdz(e) {
      e.preventDefault()

      if (!this.adz.type) {
        doAlert({
          title: 'Vous devez sélectionner 1 format',
          type: 'error',
        })
        return
      }

      this.isSaving = true

      let dbAdz = this.adz

      try {
        const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(
          this.account[0].id,
          this.$route.params.clientId,
          this.$route.params.campaignId
        )
        dbAdz = await accountClientCampaignAdzDbRef.create(this.adz)
        this.isSaving = false
      } catch (error) {
        // console.log({ error })
        doAlert({ title: error, type: 'error' })
        this.isSaving = false
        return
      }

      doAlert({ title: `Adz créé avec succès`, type: 'success' })

      let dbAdzId = dbAdz

      if (this.mode === 'create') {
        dbAdzId = dbAdz.id
      }

      if (this.action === 'exit') {
        this.$router.push({
          name: 'Campaign',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          },
        })
      } else {
        // Next...
        this.$router.push({
          name: this.adz.type === 'single' ? 'CreateFacebookAds' : 'CreateFacebookStories',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
            adzId: dbAdzId,
          },
        })
      }

      this.adz = {
        name: '',
        type: null,
        format: '',
        isReady: false,
        status: 'draft',
        isApproved: false,
        content: {
          media: '',
          text: '',
          url: '',
          urlText: '',
          metaTitle: '',
          metaDesc: '',
          cta: '',
        },
      }
    },
    ...mapActions('users', ['selectCampaign']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';

.inner-page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;
}

.small-outline-bt {
  position: absolute;
  top: 90px;
  left: 30px;
}

.create-fb-ads-form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;

  input[type='text'] {
    width: 450px;
    display: inline-block;
  }

  p {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .bt-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .bt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 20px);
    margin-top: 35px;
    padding: 15px 20px 15px 20px;

    &.save {
      background: $dark-grey;
    }
    p {
      position: relative;
      left: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      transition: all 0.2s ease;
    }

    &:hover {
      p {
        left: -15px;
      }
    }
  }
}

.checkbox-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(98% / 3);
    height: auto;
    padding: 35px 15px;
    cursor: pointer;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #fff;
      border: 1px solid #e0e2e6;
    }

    &.active {
      background-color: #fff;
      border: 1px solid #e0e2e6;
    }

    p {
      margin: 0;
    }

    .wrap {
      text-align: center;
    }

    img {
      display: inline-block;
      width: auto;
      height: 50px;
      margin-bottom: 5px;
    }
  }
}
</style>
